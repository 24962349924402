@font-face {font-family: "fleet-icons";
  src: url('/assets/fonts/fleet-icons.eot?t=1722320967106'); /* IE9*/
  src: url('/assets/fonts/fleet-icons.eot?t=1722320967106#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("/assets/fonts/fleet-icons.woff2?t=1722320967106") format("woff2"),
  url("/assets/fonts/fleet-icons.woff?t=1722320967106") format("woff"),
  url('/assets/fonts/fleet-icons.ttf?t=1722320967106') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('/assets/fonts/fleet-icons.svg?t=1722320967106#fleet-icons') format('svg'); /* iOS 4.1- */
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'fleet-icons' !important;font-size: undefined;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  speak: never
}

.icon-account:before { content: "\ea01"; }
.icon-address_database:before { content: "\ea02"; }
.icon-alert:before { content: "\ea03"; }
.icon-bell:before { content: "\ea04"; }
.icon-boat:before { content: "\ea05"; }
.icon-calendar:before { content: "\ea06"; }
.icon-car-speed:before { content: "\ea07"; }
.icon-car-speed1:before { content: "\ea08"; }
.icon-car:before { content: "\ea09"; }
.icon-car_nav:before { content: "\ea0a"; }
.icon-check:before { content: "\ea0b"; }
.icon-chevron-down:before { content: "\ea0c"; }
.icon-chevron-left:before { content: "\ea0d"; }
.icon-chevron-right:before { content: "\ea0e"; }
.icon-chevron-up:before { content: "\ea0f"; }
.icon-clock:before { content: "\ea10"; }
.icon-crosshair:before { content: "\ea11"; }
.icon-distance-2:before { content: "\ea12"; }
.icon-distance:before { content: "\ea13"; }
.icon-download:before { content: "\ea14"; }
.icon-driver:before { content: "\ea15"; }
.icon-edit-2:before { content: "\ea16"; }
.icon-edit-3:before { content: "\ea17"; }
.icon-edit:before { content: "\ea18"; }
.icon-filter:before { content: "\ea19"; }
.icon-home:before { content: "\ea1a"; }
.icon-info:before { content: "\ea1b"; }
.icon-list:before { content: "\ea1c"; }
.icon-map-pin:before { content: "\ea1d"; }
.icon-map:before { content: "\ea1e"; }
.icon-marker-arrow-right:before { content: "\ea1f"; }
.icon-marker-arrow:before { content: "\ea20"; }
.icon-meet:before { content: "\ea21"; }
.icon-menu:before { content: "\ea22"; }
.icon-more-horizontal:before { content: "\ea23"; }
.icon-more-vertical:before { content: "\ea24"; }
.icon-navigation:before { content: "\ea25"; }
.icon-notification:before { content: "\ea26"; }
.icon-parking:before { content: "\ea27"; }
.icon-petrol:before { content: "\ea28"; }
.icon-plus:before { content: "\ea29"; }
.icon-reports:before { content: "\ea2a"; }
.icon-sailor:before { content: "\ea2b"; }
.icon-search:before { content: "\ea2c"; }
.icon-settings:before { content: "\ea2d"; }
.icon-sliders:before { content: "\ea2e"; }
.icon-speedometer:before { content: "\ea2f"; }
.icon-star-full:before { content: "\ea30"; }
.icon-star:before { content: "\ea31"; }
.icon-start-car:before { content: "\ea32"; }
.icon-statistic:before { content: "\ea33"; }
.icon-temperature:before { content: "\ea34"; }
.icon-trash-2:before { content: "\ea35"; }
.icon-trash:before { content: "\ea36"; }
.icon-truck1:before { content: "\ea37"; }
.icon-voltage:before { content: "\ea38"; }
.icon-work:before { content: "\ea39"; }
.icon-x:before { content: "\ea3a"; }
.icon-zones:before { content: "\ea3b"; }
.icon-:before { content: "\ea3c"; }
.icon-:before { content: "\ea3d"; }

