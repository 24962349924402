$top-fixed-padding: 74px;

.v-contener {
  padding: 0 space(m);

  &.v-contener--with-fixed {
    padding-top: $top-fixed-padding;
  }

  &.v-contener--with-absolute {
    position: relative;
    padding: 0px;
    height: 100%;
  }

  .perspective-filters {
    right: 0px;
    position: fixed;
    top: 100px;
  }

  .v-contener__fixed {
    padding: 0px space(m);
    top: $top-panel-menu-height;
    left: $toolbox-width;
    position: fixed;
    z-index: 2;
    background-color: $body-bg;
    right: 0px;
  }

  .v-contener__absolute {
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    background-color: gray(500);
    top: 0px;
    z-index: 2;
    padding: space() space(xxl) 0;
  }

  .v-contener__scroll {
    overflow: auto;
    height: 100%;
    padding: 110px space(xxl) space();
  }
}

.c-no-result {
  font-size: 16px;
  padding: space();
  text-align: center;
  font-weight: 600;
}

.map__wrapper {
  margin: 0px;
  position: relative;
  overflow: hidden;

  ::ng-deep {
    app-map {
      height: 100%;
      display: block;
    }
  }
}

.map__cards, .map__cards-scroll-prevent {
  height: 100%;
  position: absolute;
  top: 0px;

  background-color: transparent;
  left: 0px;
  //width: 50%;
  bottom: 0px;
}

.map__cards-scroll {
  padding: space(m) 30px space(s) 0px;
  max-height: 100%;
  overflow: auto;
  direction: rtl;

  & > div {
    direction: ltr;
  }
}

.map__cards-scroll-prevent {
  pointer-events: none;
}

.map__map {
  height: 100%;
}

