.pointer {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}

.u-route-popup-wrapper {
  //height: 100%;
  position: relative;

  & > * {
    position: absolute !important;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
  }
}

.divider-h {
  height: 1px;
  width: 100%;
  background: gray(500);
  margin: space(m) 0px;
}

.nowrap {
  white-space: nowrap;
}

.u-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-black {
  color: $black !important;
}

.text-gray {
  color: $gray !important;
}

.table-fixed {
  table-layout: fixed;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, .9) translateY(0);
  }
  30% {
    transform: scale(.9, 1.1) translateY(-15px);
  }
  50% {
    transform: scale(1.05, .95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-4px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
