ngb-datepicker {
  border: 0 !important;
  padding: space(l);
  display: block !important;
  outline: none !important;
  padding-top: 5px;
  box-shadow: $box-shadow;
  .ngb-dp-navigation-chevron {
    color: $gray-900;
  }
  ngb-datepicker-navigation-select{
    select:first-child{
      margin-right: 50px;
    }
  }
  .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
    width: rem(40);
    height: rem(40);
    padding: rem(6);
    line-height: 2rem;
    outline: none !important;
  }

  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 100% !important;
    width: 100% !important;
    border-radius: 50%;
  }

  .ngb-dp-weekday {
    font-weight: 600;
    font-style: normal;
    text-transform: capitalize;
  }

  .ngb-dp-header {
    margin-bottom: space(m);
  }

  .ngb-dp-month-name {
    font-size: 13px;
    color: $primary;
    text-transform: capitalize;
    font-weight: 700;

  }

  .ngb-dp-months {
    justify-content: space-between;
  }

  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }

  .custom-day.focused {
    background-color: #e6e6e6;
  }

  .custom-day.today {
    background-color: $green;
    color: white;
  }

  .custom-day.range, .custom-day:hover {
    background-color: $primary;
    color: white;
  }

  .custom-day.faded {
    background-color: $primary-800;
    color: white;
  }

  .custom-day.disabled {

    color: $gray-900 !important;
    background-color: transparent !important;
    opacity: .3;

  }

}
