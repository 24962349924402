// base
@forward './theme_settings';
@import './../../node_modules/bootstrap/scss/functions';
@import './tools/mixins';
@import "fonts/fleet-icons-variables";
$font-family-base: 'Poppins', sans-serif;
$base-font-weight: 500;
$base-font-size: 13px;
$base-animation-time: 300ms;
$base-animation: ease-out;
$default-border-radius: 4px;


$primary: #777FE6;
$primary-900: #9298EB;
$primary-800: #AEB2F0;
$primary-700: #C9CCF5;
$primary-600: #E4E5FA;
$primary-500: #F1F2FC;

$secondary: #3F4266;
$secondary-900: #5F6399;
$secondary-800: #7F82AD;
$secondary-700: #9FA1C2;
$secondary-600: #BFC1D6;
$secondary-500: #DFE0EB;
$secondary-400: #EFEFF4;

$indigo: #B05CFA;
$blue: #5EB9FF;
$green: #6FD69A;
$red: #F07D7D;
$yellow: #FFBA00;
$gray: #B3B3B3;
$black: #000000;
$white: #FFFFFF;

//$darkSlateBlue: #373C80;
//$secondary-900: #5F6399;
//$LanguidLavender: #CACCE6;
//$gainsboro: #D7D8E6;
//$mediumSlateBlue: #6E77FF;
//color(blue): #5EB9FF;
//$mediumAquamarine: #6FD69A;
//$lightCoral: #F07D7D;
//$pastelGreen: #63E67F;
//$lightCarminePink: #E66363;
//$mikadoYellow: #FFB916;
//$black: #000000;
//$liver: #4D4D4D;
//$dimGray: #666666;
//$gray: #BFBFBF;
//$ashGrey: #B3B3B3;
//gray(500): #EDEDED;
//$whiteSmoke: #F5F5F8;
//$white: #FFFFFF;
//$lavenderIndigo: #B45EFF;



$gray-400: #F2F2F2;
$gray-500: #E6E6E6;
$gray-600: #CCCCCC;
$gray-700: #999999;
$gray-800: #666666;
$gray-900: #333333;

$border-color: $gray-500;
$grays: (
        400: $gray-400,
        500: $gray-500,
        600: $gray-600,
        700: $gray-700,
        800: $gray-800,
        900: $gray-900
);

$colors: (
        blue: $blue,
        red: $red,
        green: $green,
        white: $white,
        gray: $gray,
);

$statuses: (
        CUSTOMER_PARK: $blue,
        BRANCH_WORK_PARK: $indigo,
        OTHER_WORK_PARK: $red,
        NONWORK_PARK: $gray,
        BUSINESS_RIDE: $green,
        PRIVATE_RIDE: $gray,
);

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        success: $green,
        info: $indigo,
        warning: $yellow,
        danger: $red,
        light: $gray-400,
        dark: $gray-900,
);


$body-bg: #F5F5F8;
$body-color: $gray-900;

// BS modal
$modal-backdrop-bg: $secondary-600;
$modal-backdrop-opacity: .8;

// BS tooltip
$tooltip-bg: $primary;
$tooltip-color: $white;
$tooltip-padding-y: 3px;
$tooltip-padding-x: 5px;

$shadow-small: 0 1px 2px rgba(#14205d, .09);
$shadow-small-hover: 0 3px 5px rgba(#14205d, .09);

// width & heights
$top-panel-menu-height: 90px;
$toolbox-width: 67px;
$popup-view-max-width: 785px;
$popup-view-max-lg-width: 1166px;
$popup-view-max-sm-width: 400px;
// shadows
$box-shadow: 0 8px 12px 0 rgba(155, 166, 181, 0.1);

$cubic-transition: cubic-bezier(.25, .8, .25, 1);


$sizes: (
        xs: 10px,
        s: 15px,
        m: 25px,
        l: 35px,
        xl: 45px,
        xxl: 50px,
        xxxl: 65px,
);

$spacers: (
        xs: 5px,
        s: 10px,
        m: 15px,
        xm: 20px,
        l: 25px,
        xl: 30px,
        xxl: 60px,
);

$border-radius: size(xs);
$border-radius-sm: 5px;
