*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-size: $base-font-size;
}

iframe {
  border: 0px;
}

body {
  position: relative;
  height: 100%;
  margin: 0;
  color: gray(900);
  font-family: $font-family-base;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  //overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

}

a {
  color: inherit;
  text-decoration: none;
}

.c-map-marker-drag {
  width: 16px;
  border-radius: 50%;
  border: 2px solid white;
  background: $primary;
  cursor: move;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}

.cdk-scroll {
  height: 100%;
  width: 550px;

  direction: rtl;

  .cdk-virtual-scroll-content-wrapper {
    padding: 10px 0px;
  }

  app-map-card {
    direction: ltr;
  }
}
