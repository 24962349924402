$icon-account: "\ea01";
$icon-address_database: "\ea02";
$icon-alert: "\ea03";
$icon-bell: "\ea04";
$icon-boat: "\ea05";
$icon-calendar: "\ea06";
$icon-car-speed: "\ea07";
$icon-car-speed1: "\ea08";
$icon-car: "\ea09";
$icon-car_nav: "\ea0a";
$icon-check: "\ea0b";
$icon-chevron-down: "\ea0c";
$icon-chevron-left: "\ea0d";
$icon-chevron-right: "\ea0e";
$icon-chevron-up: "\ea0f";
$icon-clock: "\ea10";
$icon-crosshair: "\ea11";
$icon-distance-2: "\ea12";
$icon-distance: "\ea13";
$icon-download: "\ea14";
$icon-driver: "\ea15";
$icon-edit-2: "\ea16";
$icon-edit-3: "\ea17";
$icon-edit: "\ea18";
$icon-filter: "\ea19";
$icon-home: "\ea1a";
$icon-info: "\ea1b";
$icon-list: "\ea1c";
$icon-map-pin: "\ea1d";
$icon-map: "\ea1e";
$icon-marker-arrow-right: "\ea1f";
$icon-marker-arrow: "\ea20";
$icon-meet: "\ea21";
$icon-menu: "\ea22";
$icon-more-horizontal: "\ea23";
$icon-more-vertical: "\ea24";
$icon-navigation: "\ea25";
$icon-notification: "\ea26";
$icon-parking: "\ea27";
$icon-petrol: "\ea28";
$icon-plus: "\ea29";
$icon-reports: "\ea2a";
$icon-sailor: "\ea2b";
$icon-search: "\ea2c";
$icon-settings: "\ea2d";
$icon-sliders: "\ea2e";
$icon-speedometer: "\ea2f";
$icon-star-full: "\ea30";
$icon-star: "\ea31";
$icon-start-car: "\ea32";
$icon-statistic: "\ea33";
$icon-temperature: "\ea34";
$icon-trash-2: "\ea35";
$icon-trash: "\ea36";
$icon-truck1: "\ea37";
$icon-voltage: "\ea38";
$icon-work: "\ea39";
$icon-x: "\ea3a";
$icon-zones: "\ea3b";
$icon-: "\ea3c";
$icon-: "\ea3d";

