$table-border-radius: $border-radius;
.table {
  border-collapse: separate;
  border-spacing: 0 space(s);
  min-width: 100%;

  td:first-child, th:first-child {
    border-bottom-left-radius: $table-border-radius;
    border-top-left-radius: $table-border-radius;
  }

  td:last-child, th:last-child {
    border-bottom-right-radius: $table-border-radius;
    border-top-right-radius: $table-border-radius;
  }

  thead {

  }

  th, thead th {
    font-weight: 700;
    border: 0 !important;
    padding: space(s) space(s);
    color: white;
    font-size: 13px;
    background: $secondary-900;
    vertical-align: center;
  }

  td {
    border: 0;
    vertical-align: middle;
    color: gray(900);
    font-weight: 600;
    height: 50px;
    padding: 7px space(s);
    background-color: white;


  }

  tr {
    &.is-override {
      td:last-child {
        border-right: 4px solid $red;
      }
    }


    &.is-override-left {
      td:first-child {
        border-left: 4px solid $red;
      }
    }
  }

  td:first-child, th:first-child {
    padding-left: 30px;
  }

  .sum-row {
    td {
      border-style: solid;
      background-color: $secondary-500;
      border-color: $secondary-900;
      border-width: 1px 0px;

      p {
        margin: 0px;
      }
    }

    td:first-child {
      border-width: 1px 0px 1px 1px;
    }

    td:last-child {
      border-width: 1px 1px 1px 0px;
    }

  }


  &.table--with-actions {
    margin-right: 48px;


    td {
      white-space: nowrap;
    }

    tr {
      overflow: hidden;

    }

    .table-action {
      min-width: 50px;
    }
  }

  &.table--sm {
    th {
      font-size: 11px;
      padding: 12px 8px 12px 10px;
    }

    td {
      padding: 12px 8px;
      font-size: 9px;
    }
  }
}

.table__scroll-wrapper {
  width: 100%;
  overflow: auto;
}

.table__scroll-padding {
  padding-right: 0px;
}
app-data-grid-row.is-override-left {
  .data-grid-row {
    border-left: 4px solid $red;
  }
}
