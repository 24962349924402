@mixin btn-style($name, $bgColor, $disableBg: $secondary-500) {
  .btn-#{$name} {
    color: $white;
    background-color: $bgColor;
    border: 2px solid $bgColor;
    box-shadow: none !important;

    @include hover-focus {
      background-color: darken($bgColor, 5);
      border-color: darken($bgColor, 5);
      text-decoration: none;
    }

    &:active {
      background-color: darken($bgColor, 10);
      border-color: darken($bgColor, 10);
    }

    &.btn-border {
      background: white;
      color: $bgColor;
      @include hover-focus {
        background-color: darken(white, 5);
      }

      &:active {
        background-color: darken(white, 8) !important;
        border-color: $bgColor !important;
        color: $bgColor !important;
      }
    }

    &.btn-sm {
      padding: 0.655rem 1.5rem;
    }

    &:disabled {
      color: $bgColor;
      background: $disableBg;
      border: 2px solid $disableBg;

      &:hover, &:active {
        background: $disableBg !important;
        color: $bgColor !important;
        text-decoration: none;
      }
    }
  }
}


.btn {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  border-radius: $border-radius-sm;
  outline: 0 !important;
  transition: all .25s ease-in-out;
  padding: 0.847rem 1.5rem;
  box-shadow: none !important;
}

@include btn-style('primary', $primary, $primary-600);
@include btn-style('secondary', $secondary-900, $secondary-500);
@include btn-style('white', $white, $secondary-500);
@include btn-style('danger', $red);
.btn-white {
  &.btn-border {
    background: transparent;
    @include hover-focus {
      color: $secondary-900;
    }
  }
}


//.btn-text {
//  background-color: transparent;
//  border: none;
//  box-shadow: none !important;
//  min-height: 32px;
//
//  &:hover {
//
//  }
//
//  &.btn-primary {
//    color: theme-color() !important;
//
//    &:hover {
//      background-color: darken(theme-color(), 10%);
//    }
//
//    &:active, &:focus {
//      background-color: darken(theme-color(), 15%) !important;
//      outline: none !important;
//    }
//  }
//
//  &.btn-secondary {
//    &:hover {
//      background-color: gray(500) !important;
//    }
//
//    &:active, &:focus {
//      background-color: darken(gray(500), 10%) !important;
//      outline: none !important;
//    }
//  }
//}

.btn-fab {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 14px;
  padding: 0px;
  line-height: 32px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}

.btn-icon {
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  padding: 0px;
  line-height: 40px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);

  &.btn-sm {
    width: 30px;
    height: 30px;
    padding: 0px;
    font-size: 16px;
    line-height: 30px;
  }
}

.btn-sm {

}

.btn-link {
  font-weight: 600;
  color: theme-color();
  font-size: 13px;
}

.c-ignition {
  background-color: theme-color(success);
  border-radius: 50%;
  width: 14px;
  display: inline-block;
  border: 2px solid white;
  height: 14px;

}
