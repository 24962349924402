@import 'node_modules/ngx-toastr/toastr-old.css';
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$theme-colors: map-remove($theme-colors, "info", "light", "dark");
$colors: map-remove($theme-colors, "info", "light", "dark");
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/utilities/text";
@import "bootstrap/scss/grid";
@import "ngb-datepicker";
body > .dropdown {
  z-index: 10;
}

.tooltip-inner {
  font-weight: 600;
}
ngb-pagination{
  margin-top: 5px;
  display: block;
}
