.modal-content {
  border-radius: 0px;
  border: none;
  padding:0px;
  background: transparent;
  & > *{
    border-radius: $border-radius-sm;
    background: white;
  }
  & > * {
    background-color: white;
  }
  .close {
    color: white;
    cursor: pointer;
    font-size: 14px;
  }
}
ngb-modal-window.modal{
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  .modal-dialog{
    width: 100%;
  }
}
.modal-backdrop{
  background: gray(900);
}
