@import "@ng-select/ng-select/themes/default.theme.css";

.ng-select {
  position: static !important;
  cursor: pointer;
  .ng-select-container{
    min-height: 20px !important;
  }
  &.ng-select-opened {
    .ng-arrow-wrapper {
      transform: rotateX(180deg);
    }
  }
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background: none;
    margin: 0px;
    padding: 0px;
    .ng-value-icon{
      display: none;
    }
  }
  .ng-value-container {
    color: theme-color();
    font-weight: 600;
    font-size: 13px;
    font-family: $font-family-base;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: $body-bg;
    color: theme-color(secondary);
    font-size: 13px;
  }

  .ng-arrow-wrapper {
    position: absolute;
    width: 20px;
    transition: transform .35s $cubic-transition;
    display: inline-block;
    height: 20px;
    right: 0px;
    top: -10px;
    line-height: 20px;
    text-align: center;
    bottom: 20px;
    font-family: 'fleet-icons' !important;
    speak: none;
    font-style: normal;
    transform-origin: center;
    color: theme-color();
    border: 0;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0px;
    font-size: 20px;
    font-weight: 700;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
      content: $icon-chevron-down;
    }

    .ng-arrow {
      display: none !important;
    }

  }

  .ng-select-container {
    box-shadow: none !important;
    outline: none !important;
    font-family: $font-family-base;
    background: transparent !important;
    border: 0 !important;
    overflow: visible !important;
    padding-left: 0 !important;

    .ng-value-container {
      padding: 0px !important;
    }
  }

  &.ng-select-single .ng-select-container {
    height: 20px !important;
    padding-top: 5px;
    min-height: 20px !important;
  }
}

.ng-dropdown-panel {
  margin-top: -5px;
  background-color: $body-bg !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none !important;
}
